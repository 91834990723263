<template>
  <!--begin::Container-->
  <div>
    <div
      id="kt_subheader"
      class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <div class="text-lg-right"></div>
        </div>

        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            Supplier Detail Page
          </h5>
          <!--end::Page Title-->
          <!--begin::Action-->
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
          ></div>

          <span class="text-muted font-weight-bold mr-4">
            {{ companyname }}</span
          >
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
          ></div>

          <!--end::Action-->
        </div>
        <!--end::Info-->
      </div>
    </div>

    <div
      class="col-lg-12  background-cover justify-content-center align-items-center"
    >
      <div class="media pt-1">
        <v-img
          rounded
          :src="coverUrl"
          height="200"
          class="background-cover rounded-img"
          alt="..."
        >
        </v-img>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <!--begin::Card-->
        <div class="mt-1  card card-custom gutter-b rounded-img">
          <div class="card-body">
            <!--begin::Details-->
            <div class="d-flex mb-4">
              <!--begin: Pic-->

              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a
                      href="#"
                      class="text-dark-75 text-hover-primary font-size-h1 font-weight-bold mr-3"
                      >{{ companyname }}
                    </a>
                  </div>
                </div>
                <!--end::Title-->
                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap mb-4">
                      <a
                        href="#"
                        class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      >
                        <i class="flaticon2-new-email mr-2 font-size-lg"></i
                        >{{ email }}</a
                      >

                      <a
                        href="#"
                        class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-10 mb-lg-0 mb-2"
                      >
                        <i class="flaticon2-calendar-3 mr-2 font-size-lg"></i
                        >{{ category }}</a
                      >
                      <a
                        href="#"
                        class="text-dark-50 text-hover-primary font-weight-bold"
                      >
                        <i class="flaticon2-placeholder mr-2 font-size-xl"></i
                        >{{ city }}, {{ state }}</a
                      >
                      <div v-show="linkedin != ''">
                        <a
                          :href="linkedin"
                          class="text-dark-50 ml-16 text-hover-primary font-weight-bold"
                        >
                          <i
                            class="flaticon2-placeholder mr-2 font-size-xlg"
                          ></i
                          >Linkedin
                        </a>
                      </div>
                      <div v-show="instagram != ''">
                        <a
                          :href="instagram"
                          class="text-dark-50 ml-16 text-hover-primary font-weight-bold"
                        >
                          <i
                            class="flaticon2-placeholder mr-2 font-size-xlg"
                          ></i
                          >Instagram
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
            <div class="separator separator-solid"></div>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="d-flex  align-items-center  flex-lg-fill mr-5 mb-2">
                  <span class="mr-4">
                    <i
                      class="flaticon-piggy-bank display-4 text-muted font-weight-bold"
                    ></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75 pr-6">
                    <span class="font-weight-bolder font-size-lg"
                      >Average Margin</span
                    >
                    <span class="font-weight-bolder font-size-h5">
                      {{ averageMargin }}%</span
                    >
                  </div>

                  <span class="pl-4">
                    <i
                      class="flaticon-piggy-bank display-4 text-muted font-weight-bold"
                    ></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75 pl-5">
                    <span class="font-weight-bolder font-size-lg"
                      >MDF - Marketing</span
                    >
                    <span class="font-weight-bolder font-size-h5">
                      {{ mdf }}% of Gross</span
                    >
                  </div>
                </div>
                <!--end::Item-->
              </li>
              <li class="list-group-item">
                <div class="d-flex  align-items-center  flex-lg-fill mr-5 mb-2">
                  <span class="mr-4">
                    <i
                      class="flaticon-piggy-bank display-4 text-muted font-weight-bold"
                    ></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75 pr-6">
                    <span class="font-weight-bolder font-size-lg"
                      >How I sell:</span
                    >
                    <span class="font-weight-bolder font-size-lg">{{
                      salesModel
                    }}</span>
                  </div>

                  <span class="pl-4">
                    <i
                      class="flaticon-piggy-bank display-4 text-muted font-weight-bold"
                    ></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75 pl-5">
                    <span class="font-weight-bolder font-size-lg"
                      ># of Sellers</span
                    >
                    <span class="font-weight-bolder font-size-h5"> 2,300</span>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex  align-items-center  flex-lg-fill mr-5 mb-2">
                  <span class="mr-4">
                    <i
                      class="flaticon-piggy-bank display-4 text-muted font-weight-bold"
                    ></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75 pr-6">
                    <span class="font-weight-bolder font-size-lg"
                      >MAP Policy</span
                    >
                    <b-modal v-model="policyShow" hide-footer>
                      <template #modal-title>
                        Map Policy
                      </template>
                      <div class="d-block text-center">
                        <h3 v-if="mapPolicy != ''" v-html="mapPolicy"></h3>
                        <h3 v-else>CONTACT FOR MORE INFO</h3>
                      </div>
                    </b-modal>
                    <a
                      v-if="hasPolicy"
                      href="#"
                      class="text-primary font-weight-bolder"
                      @click="policyShow = !policyShow"
                    >
                      Yes
                    </a>
                    <span v-else class="font-weight-bolder">
                      No
                    </span>
                  </div>

                  <span class="pl-4">
                    <i
                      class="flaticon-piggy-bank display-4 text-muted font-weight-bold"
                    ></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75 pl-5">
                   
                    >
                    <a href="#" class="text-primary font-weight-bolder">View</a>
                  </div>
                </div>
              </li>
            </ul>

            <!--begin::Items-->
          </div>
        </div>
        <!--end::Card-->

        <div class="card rounded-img card-custom  gutter-b shadow">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Featured Products</span
              >
              <span class="text-muted mt-3 font-weight-bold font-size-sm"
                >View all Items
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <!--begin::Table-->

            <ul class="list-group ">
              <!-- list group item-->
              <li
                v-for="(product, index) in products"
                :key="index"
                class="list-group-item"
              >
                <!-- Custom content-->
                <div
                  class="media align-items-lg-center flex-column flex-lg-row p-3"
                >
                  <div class="media-body order-2 order-lg-1">
                    <h5 class="mt-0 font-weight-bold mb-2">
                      {{ product.name }}
                    </h5>

                    <p
                      class="font-italic mb-0 "
                      v-html="product.short_description"
                    >
                      {{ product.short_description }}
                    </p>

                    <div
                      class="d-flex align-items-center justify-content-between mt-1"
                    >
                      <h6 class="font-weight-bold my-2">
                        Case Pack - {{ product.case_pack }} units
                      </h6>
                      <ul class="list-inline small">
                        <li class="list-inline-item m-0">
                          <i class="fa fa-star text-success"></i>
                        </li>
                        <li class="list-inline-item m-0">
                          <i class="fa fa-star text-success"></i>
                        </li>
                        <li class="list-inline-item m-0">
                          <i class="fa fa-star text-success"></i>
                        </li>
                        <li class="list-inline-item m-0">
                          <i class="fa fa-star text-success"></i>
                        </li>
                        <li class="list-inline-item m-0">
                          <i class="fa fa-star-o text-gray"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <img
                    :src="
                      product.main_image != null
                        ? baseurl + product.main_image.url
                        : '/media/bike1.jpg'
                    "
                    alt="Generic placeholder image"
                    width="200"
                    class="ml-lg-5 order-1 order-lg-2"
                  />
                </div>
                <!-- End -->
              </li>
              <!-- End -->
            </ul>
            <!-- End -->

            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>

      <div class="col-lg-6">
        <v-card :loading="loading" class="mx-auto   " shaped>
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img height="250" :src="descImgUrl"></v-img>

          <v-card-title>Who we are</v-card-title>

          <v-card-text>
            <p class="card-text">
              <span
                class="font-weight-bold  text-dark-50"
                v-html="teaserData"
              ></span>
            </p>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-text>
            <a
              href="#"
              class="btn  btn-light-success font-weight-bolder text-uppercase mr-3"
              >Contact</a
            >

            <a
              href="#"
              class="btn  btn-light-success font-weight-bolder text-uppercase mr-3"
              >Apply</a
            >
          </v-card-text>
        </v-card>

        <div class="card card-custom  mt-5  gutter-b shadow rounded-img">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Our Story</span
              >
              <span class="text-muted mt-3 font-weight-bold font-size-sm"
                >about us
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <div class="card-body pt-4 pb-0">
            <div class="row">
              <div class="col-lg-9">
                <span
                  class="font-weight-bold text-dark-50"
                  v-html="descData"
                ></span>
              </div>
            </div>
          </div>
        </div>

        <div class="card card-custom gutter-b rounded-img">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title font-weight-bolder">Dealer Requirements</h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body p-0 position-relative overflow-hidden">
            <div class="pt-5">
              <div
                class=" align-items-lg-center flex-column flex-lg-row p-3"
                v-html="dealerData"
              ></div>
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>

    <!--begin::Row-->
    <!--end::Row-->

    <!--end::Container-->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";

import gql from "graphql-tag";

export default {
  data() {
    return {
      baseurl: "",
      file: null,
      coverUrl: "",
      descImgUrl: "",
      companyname: "",
      city: "",
      state: "",
      email: "",
      phone: "",
      website: "",
      averageMargin: "",
      category: "",
      mdf: "",
      mapPolicy: "",
      hasPolicy: false,
      salesModel: "",
      teaserData: "",
      descData: "",
      instagram: "",
      linkedin: "",
      dealerData: "",
      policyShow: false,
      hasQuestion: false,
      questionId: null,
      hasApplication: false,
    };
  },
  apollo: {
    products: {
      query: gql`
        query Products($supplier: ID!) {
          products: products(
            where: { supplier: { id: $supplier }, featured: true }
          ) {
            id
            name
            case_pack
            part_id
            short_description
            main_image {
              url
            }
          }
        }
      `,
      variables() {
        return {
          supplier: this.$store.state.auth.user.company_relation,
        };
      },
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    setTimeout(() => {
      this.getCompanyInfo({
        id: this.$route.params.id,
      }).then((res) => {
        if (res.data.has_question != null) {
          this.hasQuestion = res.data.has_question;
        }
        if (res.data.question != null) {
          this.questionId = res.data.question.id;
        }
        var baseurl =
          process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337";
        if (
          res.data.profile.profile_cover != null &&
          res.data.profile.profile_cover != undefined
        ) {
          this.coverUrl = baseurl + res.data.profile.profile_cover.url;
        }
        if (
          res.data.profile.profile_desc_img != null &&
          res.data.profile.profile_desc_img != undefined
        ) {
          this.descImgUrl = baseurl + res.data.profile.profile_desc_img.url;
        }
        this.companyname = res.data.profile.profile_company_name;
        this.city = res.data.profile.profile_city;
        this.state = res.data.profile.profile_state;
        this.email = res.data.profile.profile_contact_email;
        this.phone = res.data.profile.profile_phone;
        this.instagram = res.data.profile.instagram;
        this.linkedin = res.data.profile.linkedin;
        this.website = res.data.profile.profile_website;

        this.averageMargin = res.data.profile.profile_average_margin;
        this.mdf = res.data.profile.profile_mdf_percent;
        this.mapPolicy = res.data.profile.profile_map_text;
        this.hasPolicy = res.data.profile.profile_map_policy;
        this.salesModel = res.data.profile.profile_sales_model;
        this.category = res.data.profile.profile_category;
        if (
          res.data.profile.profile_teaser_description != null &&
          res.data.profile.profile_teaser_description != undefined
        ) {
          this.teaserData = res.data.profile.profile_teaser_description;
        }
        if (
          res.data.profile.profile_long_description != null &&
          res.data.profile.profile_long_description != undefined
        ) {
          this.descData = res.data.profile.profile_long_description;
        }
        if (
          res.data.profile.profile_dealer_requirements != null &&
          res.data.profile.profile_dealer_requirements != undefined
        ) {
          this.dealerData = res.data.profile.profile_dealer_requirements;
        }

        this.getApplicationInfo({
          supplier_company: this.$route.params.id,
          seller_company: this.$store.state.auth.user.company_relation,
        }).then((res) => {
          if (res.data.length == 0) {
            this.hasApplication = false;
          } else {
            this.hasApplication = true;
          }
        });
      });
    }, 1000);
  },
  methods: {
    ...mapActions([
      "getCompanyInfo",
      "getApplicationInfo",
      "submitApplication",
    ]),
    apply() {
      if (this.hasApplication == false) {
        if (this.hasQuestion) {
          this.$router.push(
            "/seller/applicationanswer/" + this.$route.params.id
          );
        } else {
          this.submitApplication({
            supplier_company: this.$route.params.id,
            seller_company: this.$store.state.auth.user.company_relation,
            status: "pending",
          })
            .then(() => {
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              this.hasApplication = true;
            })
            .catch(() => {
              this.$bvToast.toast("An Error Occured!", {
                title: "Error",
                variant: "danger",
                toaster: "b-toaster-bottom-right",
                solid: true,
                appendToast: true,
              });
            });
        }
      }
    },
  },
};
</script>

<style scoped>
.rounded-img {
  border-radius: 22px;
}
.shaped {
  clip-path: polygon(0% 0%, 0% 85%, 50% 100%, 100% 85%, 100% 0%);
}
.content {
  padding: 1px 0;
}

.bg-main {
  background-color: #dce6e5;
}
</style>
